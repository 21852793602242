<template>
  <div class="screen">
    <div class="wrap">
      <a class="back-icon" href="javascript:history.back(-1)">
        <img alt="" src="../assets/images/left-arrow.png" />
      </a>
      <div class="btn">
        <div class="ios" @click="downApp('https://bu4g88hp.com/xDrG.app')">
          <svg
            class="icon"
            height="25"
            p-id="3114"
            t="1597976875066"
            version="1.1"
            viewBox="0 0 1024 1024"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M791.488 544.095c-1.28-129.695 105.76-191.871 110.528-194.975-60.16-88.032-153.856-100.064-187.232-101.472-79.744-8.064-155.584 46.944-196.064 46.944-40.352 0-102.816-45.76-168.96-44.544-86.912 1.28-167.072 50.528-211.808 128.384-90.304 156.703-23.136 388.831 64.896 515.935 43.008 62.208 94.304 132.064 161.632 129.568 64.832-2.592 89.376-41.952 167.744-41.952s100.416 41.952 169.056 40.672c69.76-1.312 113.984-63.392 156.704-125.792 49.376-72.16 69.728-142.048 70.912-145.632-1.536-0.704-136.064-52.224-137.408-207.136zM662.56 163.52C698.304 120.16 722.432 60 715.84 0c-51.488 2.112-113.888 34.304-150.816 77.536-33.152 38.368-62.144 99.616-54.368 158.432 57.472 4.48 116.128-29.216 151.904-72.448z"
              fill="#fff"
              p-id="3115"
            ></path>
          </svg>
          ios版本下載
        </div>
        <div class="android" @click="downApp('/syphon.apk')">
          <svg
            class="icon"
            height="25"
            p-id="2254"
            t="1597976799608"
            version="1.1"
            viewBox="0 0 1024 1024"
            width="25"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M141.1 331.2c-35 0-63.3 28.3-63.3 63.2v264.7c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2V394.4c0-34.9-28.3-63.2-63.3-63.2zM228.9 753.3c0 37.3 30.3 67.5 67.6 67.5h46.1v140c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2v-140h85.5v140c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2v-140h46.1c37.3 0 67.6-30.2 67.6-67.5V343.1H228.9v410.2zM651.8 93.4L696 13c2.4-4.2 0.8-9.6-3.5-11.9-4.2-2.3-9.6-0.8-11.9 3.5l-44.7 81.2C598.4 69.2 556.4 59.9 512 59.9c-44.4 0-86.4 9.3-123.9 25.8L343.4 4.6c-2.3-4.3-7.7-5.8-11.9-3.5-4.3 2.3-5.9 7.7-3.5 11.9l44.2 80.4c-86.8 44.8-145.5 130-145.5 227.7h570.5c0.1-97.8-58.6-182.9-145.4-227.7zM382.2 218c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9 23.9 10.7 23.9 23.9c-0.1 13.2-10.8 23.9-23.9 23.9z m259.7 0c-13.2 0-23.9-10.7-23.9-23.9s10.7-23.9 23.9-23.9 23.9 10.7 23.9 23.9c-0.1 13.2-10.8 23.9-23.9 23.9zM882.9 331.2c-35 0-63.3 28.3-63.3 63.2v264.7c0 34.9 28.4 63.2 63.3 63.2 35 0 63.3-28.3 63.3-63.2V394.4c0-34.9-28.3-63.2-63.3-63.2z"
              fill="#fff"
              p-id="2255"
            ></path>
          </svg>
          Android版本下載
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import apk from '@/assets/android.apk'

export default {
  name: 'pageError',
  data() {
    return {}
  },
  methods: {
    downApp(url) {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/style/function.scss';

.screen {
  width: 100vw;
  height: 100vh;
  background: url('~@/assets/images/login-bg-1.png');
  background-size: cover;
}

.wrap {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background: center / contain no-repeat url('~@/assets/images/download_app.png');
  max-width: 30rem;
}

.back-icon {
  img {
    width: 1.2rem;
  }

  position: absolute;
  left: 0.2rem;
  top: 0.5rem;
}

/* @media screen and (max-width: 750px) { */
.wrap {
  max-width: 30rem;
  margin: 0 auto;
}

/* } */

.wrap a {
  display: block;
  color: white;
  position: absolute;
  top: 0.5rem;
  left: 0.2rem;
  font-size: 14px;
  text-decoration: underline;
}

.wrap a i {
  display: block;
  font-size: 2rem;
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
}

.android,
.ios {
  /* flex-basis: 37%; */
  text-align: center;
  padding: 0.4rem 0.6rem;
  line-height: 1.5;
  color: white;
  border: 2px solid white;
  border-radius: 10px;
  margin: 0 0.4rem;
  font-size: 14px;
}

.android > svg,
.ios > svg {
  vertical-align: middle;
}
</style>
